import React, { FC, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  useTheme,
  Theme,
  TableCell,
  ListItemText,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import {
  reportResponseType,
  reportsRequestType,
  reportsResponseType,
  reportsCsvResponseType,
  reportType,
  standardTagsResponseType,
} from "../common/type";
import {
  useAuth0Token,
  getReports,
  getStandardTags,
  outputReportsCsv,
} from "../common/http-requests";
import { Link } from "react-router-dom";
import { BasicTextAtom } from "../components/atoms/BasicTextAtom";
import { Store } from "../store";
import { HighlightTextAtom } from "../components/atoms/HighlightTextAtom";
import { InputFormMolecule } from "../components/molecules/InputFormMolecule";
import { DatePickerComponent } from "../components/DatePickerComponent";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { PaddingAtom } from "../components/atoms/PaddingAtom";

const DefaultPageIndex = 0;
const DefaultCountPerPage = 10;
const DefaultCountPerPages = [10, 25, 50];

export const ReportListPage: FC = () => {
  const { getToken } = useAuth0Token();
  const [isSending, setIsSending] = useState(true);
  const { state } = useContext(Store);

  // material ui css
  const theme = useTheme();
  function getStyles(name: string, personName: string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  // 記録
  const [reportsData, setReportsData] = useState<reportsResponseType>({
    total_count: 0,
    content: [],
  });

  // ダッシュボード画面のタグ・学生一覧画面から遷移してきた場合
  const location = useLocation();
  const { stdTagId, userTagName, reportDate, studentNumber } = location.state;

  // YYYY/MM/DDに変換
  const parseDate = (period: Date | null) => {
    const year = period?.getFullYear();
    // getMonthの値は0起点のため+1
    const month = period ? period.getMonth() + 1 : undefined;
    const date = period?.getDate();

    if (!(year && month && date)) return "";
    return `${year}/${month}/${date}`;
  };

  const string2Date = (reportDate: string) => {
    const year = parseInt(reportDate.substring(0, 4));
    const month = parseInt(reportDate.substring(5, 7));
    const day = parseInt(reportDate.substring(8, 10));
    const date = new Date(year, month - 1, day);
    return date;
  };

  // 開始日
  const [startDate, setStartDate] = useState<Date | undefined>(
    reportDate ? string2Date(reportDate) : undefined
  );
  const [parsedStartDate, setParsedStartDate] = useState<string>(
    reportDate ? reportDate : ""
  );
  // 終了日
  const [endDate, setEndDate] = useState<Date | undefined>(
    reportDate ? string2Date(reportDate) : undefined
  );
  const [parsedEndDate, setParsedEndDate] = useState<string>(
    reportDate ? reportDate : ""
  );

  //鳴門パースペクティブ
  const [standardTags, setStandardTags] = useState<standardTagsResponseType>({
    content: [],
  });

  const [selectedStandardTagsArray, setSelectedStandardTagsArray] = useState<
    Array<any>
  >([]);

  //鳴門パースペクティブを空白区切りの文字列に変換
  const [selectedStandardTags, setSelectedStandardTags] = useState<string>("");

  // タグ検索に使用する
  const initialUserTagsState = userTagName ? userTagName : "";
  const [userTags, setUserTags] = useState<string>(initialUserTagsState);

  //学籍番号検索に使用する
  const [studentNumbersState, setStudentNumbersState] = useState<string>("");

  const [pageIndex, setPageIndex] = useState(DefaultPageIndex);
  const [countPerPage, setCountPerPage] = useState(DefaultCountPerPage);

  // 記録種類のリスト
  const [reportTypes, setReportTypes] = useState<number[]>([0, 1, 2, 3, 4, 5, 99]);
  const reportTypesArray: Array<reportType> = [
    { id: 0, value: "セルフデザイン型学修 - 週録" },
    { id: 1, value: "教育実習 - 週録" },
    { id: 2, value: "セルフデザイン型学修 - 省察（前期末／後期末）" },
    { id: 3, value: "セルフデザイン型学修 - セルデザクエスト" },
    { id: 4, value: "教育実習 - 日録" },
    { id: 5, value: "教育実習 - 全体の振り返り" },
    { id: 99, value: "その他" },
  ];
  const getReportTypeGridLayout = (reportTypeId:number) => {
    // gridRow, gridColumn の順番で返す
    if (reportTypeId === 0)
      return [1, 1];
    if (reportTypeId === 1)
      return [2, 2];
    if (reportTypeId === 2)
      return [2, 1];
    if (reportTypeId === 3)
      return [3, 1];
    if (reportTypeId === 4)
      return [1, 2];
    if (reportTypeId === 5)
      return [3, 2];
    return [1, 3];
  };
  //reportTypesのチェックボックスを管理するためのisChecked
  const [isChecked, setIsChecked] = useState<boolean[]>(
    //@ts-ignore
    reportTypesArray.slice().fill(false) as boolean[]
  );
  const toggleCheckboxValue = (index: number) => {
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
  };

  const [isHighlight, setIsHighlight] = useState<boolean>(
    reportDate ? true : false
  );

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  useEffect(() => {
    const selectedReportTypes: any[] = [];
    reportTypesArray.map((reportType, index) => {
      isChecked[index] && selectedReportTypes.push(reportType.id);
    });
    setReportTypes(selectedReportTypes);
  }, [isChecked]);

  // Reactの仕様でuseEffectが二回動いていたので、１回に制限
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getStandardTagsData();
        setStudentNumbersState(
          studentNumber // 学生一覧から遷移してきた場合
        );
        await getReportsData(
          pageIndex,
          countPerPage,
          Number(state.userId),
          state.userType,
          parsedStartDate,
          parsedEndDate,
          reportTypes,
          newStandardTagsArray.join(" "), // 初回のみダッシュボードから渡ってきたstateをもとに検索
          userTags,
          studentNumber // stateの更新は間に合わないので、初回のみuseLocationの値をそのまま使用
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, [location]);

  // 日付が選択されるたびにstartDate, endDateを更新
  useEffect(() => {
    startDate && setParsedStartDate(parseDate(startDate));
    endDate && setParsedEndDate(parseDate(endDate));
  }, [startDate, endDate]);

  const newStandardTagsArray: any[] = [];

  // 鳴門パースペクティブを取得してセレクトボックスに表示
  const getStandardTagsData = async () => {
    setIsSending(true);
    try {
      const data: standardTagsResponseType = await getStandardTags(
        await getToken()
      );
      setStandardTags(data);

      // ダッシュボード画面からタグを選択してジャンプしてきた際の動作（formに表示するだけで検索は別）
      stdTagId && newStandardTagsArray.push(stdTagId);
      setSelectedStandardTagsArray(newStandardTagsArray);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };
  // 空白区切りの文字列に変換
  useEffect(() => {
    const convertSelectedStandardTagsToString = () => {
      setSelectedStandardTags(selectedStandardTagsArray.join(" "));
    };
    convertSelectedStandardTagsToString();
  }, [selectedStandardTagsArray]);

  const getReportsData = async (
    page_index?: number,
    count_per_page?: number,
    login_user_id?: number, // userResponseType.id の値
    login_user_type?: number, // 0：教職員、1：学生
    start_date?: string, // yyyy/mm/dd形式
    end_date?: string, // yyyy/mm/dd形式
    report_types?: number[], // 0：週報、1：実習、2：省察、99：その他  TODO：UIと要調整
    standard_tags?: string, // 数値の空白区切り。数値は standardTagResponseType.id の値
    user_tags?: string, // タグ文字列の空白区切り。タグ文字列は任意。
    student_numbers?: string
  ) => {
    setIsSending(true);
    try {
      const requestParams: reportsRequestType = {
        page_index,
        count_per_page,
        login_user_id,
        login_user_type,
        start_date,
        end_date,
        report_types,
        standard_tags,
        user_tags,
        student_numbers,
      };
      const data: reportsResponseType = await getReports(
        await getToken(),
        requestParams
      );
      setReportsData(data);
    } catch (e) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const outputReportsCsvData = async (
    // page_index?: number,
    // count_per_page?: number,
    login_user_id?: number, // userResponseType.id の値
    login_user_type?: number, // 0：教職員、1：学生
    start_date?: string, // yyyy/mm/dd形式
    end_date?: string, // yyyy/mm/dd形式
    report_types?: number[], // 0：週報、1：実習、2：省察、99：その他  TODO：UIと要調整
    standard_tags?: string, // 数値の空白区切り。数値は standardTagResponseType.id の値
    user_tags?: string, // タグ文字列の空白区切り。タグ文字列は任意。
    student_numbers?: string
  ) => {
    setIsSending(true);
    try {
      let page_index = 0; // 任意
      let count_per_page = 0; // 任意
      const requestParams: reportsRequestType = {
        page_index,
        count_per_page,
        login_user_id,
        login_user_type,
        start_date,
        end_date,
        report_types,
        standard_tags,
        user_tags,
        student_numbers,
      };
      const data: reportsCsvResponseType = await outputReportsCsv(
        await getToken(),
        requestParams
      );
      const signed_url = data.content;

      const link = document.createElement("a");
      link.setAttribute("href", signed_url);
      link.setAttribute("download", "reports.csv"); // 結局は、backend指定のCSVファイル名でダウンロードされる
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (e) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleSetStudentNumbersState = (e: any) => {
    setStudentNumbersState(e.target.value);
  };

  const handleSetUserTags = (e: any) => {
    setUserTags(e.target.value);
  };

  //クリアボタンを押したとき
  const handleClickClearButton = () => {
    setStartDate(undefined);
    setParsedStartDate("");
    setEndDate(undefined);
    setParsedEndDate("");
    setReportTypes([0, 1, 2, 99]);
    // @ts-ignore
    setIsChecked(reportTypesArray.slice().fill(false));
    setSelectedStandardTagsArray([]);
    setSelectedStandardTags("");
    setUserTags("");
    setStudentNumbersState("");
  };

  // 検索ボタンを押したとき
  const handleClickSearchButton = () => {
    setPageIndex(0);
    getReportsData(
      0,
      countPerPage,
      Number(state.userId),
      state.userType,
      parsedStartDate,
      parsedEndDate,
      reportTypes,
      selectedStandardTags,
      userTags,
      studentNumbersState
    );
  };

  // CSV出力ボタンを押したとき
  const handleClickOutputCSVButton = () => {
    outputReportsCsvData(
      // page_index,
      // countPerPage,
      Number(state.userId),
      state.userType,
      parsedStartDate,
      parsedEndDate,
      reportTypes,
      selectedStandardTags,
      userTags,
      studentNumbersState
    );
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPageIndex(newPage);
    getReportsData(
      newPage,
      countPerPage,
      Number(state.userId),
      state.userType,
      parsedStartDate,
      parsedEndDate,
      reportTypes,
      selectedStandardTags,
      userTags,
      studentNumbersState
    );
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newCountPerPage = parseInt(event.target.value, DefaultCountPerPage);
    setCountPerPage(newCountPerPage);

    setPageIndex(DefaultPageIndex);
    getReportsData(
      DefaultPageIndex,
      newCountPerPage,
      Number(state.userId),
      state.userType,
      parsedStartDate,
      parsedEndDate,
      reportTypes,
      selectedStandardTags,
      userTags,
      studentNumbersState
    );
  };

  // MEMO：based on kyoai-education-app manage-students.tsx
  return (
    <>
      <Container maxWidth="xl">
        <div>
          {/* className={classes.pageTitle}> */}
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Typography
                variant="h5"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                {/* TODO：style記述 */}
                記録一覧
              </Typography>
            </Grid>
          </Grid>
        </div>

        {isSending ? (
          <>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <div className="text-center">
                  <CircularProgress
                    style={{ margin: "10rem auto 0 auto", textAlign: "center" }}
                    size="3rem"
                  />
                </div>
                <Typography>記録情報を取得中...</Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            {
              <FeedbackBar
                feedbackInfo={feedbackInfo}
                handleClose={handleClose}
              ></FeedbackBar>
            }
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px 0 20px 0",
              }}
            >
              <Box
                sx={{
                  width: "15%",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Link
                  to={`/reportDetail`}
                  state={{
                    userId: state.userId,
                    reportId: null,
                  }}
                  className={`h-fit`}
                >
                  <Button
                    style={{ width: "100px", fontSize: "16px" }}
                    variant="contained"
                  >
                    新規作成
                  </Button>
                </Link>
              </Box>
              <Box sx={{ width: "65%" }}>
                <InputFormMolecule inputLabel="期間">
                  <div className="flex items-center relative z-20">
                    <DatePickerComponent
                      reportDate={startDate}
                      setReportDate={setStartDate}
                    ></DatePickerComponent>
                    <div className="w-[60px] text-[20px]">~</div>
                    <DatePickerComponent
                      reportDate={endDate}
                      setReportDate={setEndDate}
                      minDate={startDate}
                    ></DatePickerComponent>
                  </div>
                </InputFormMolecule>
                <InputFormMolecule inputLabel="種類">
                  {/* <Box style={{ display: "flex" }}> */}
                  <Box style={{ width: "80%", display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)'}}>
                    {reportTypesArray.map((reportType, index) => {
                      const [gridRow, gridColumn] = getReportTypeGridLayout(reportType.id);
                      return (
                        <div
                          key={index}
                          style={{
                            gridRow: gridRow,
                            gridColumn: gridColumn,
                          }}
                        >
                          <FormGroup key={index} className="mr-8">
                            <FormControlLabel
                              style={{ zIndex: 0,
                                whiteSpace: 'nowrap', // ラベルが折り返されないようにしておく
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                               }}
                              control={
                                <Checkbox
                                  value={reportType.id}
                                  checked={isChecked[index]}
                                  onClick={() => {
                                    toggleCheckboxValue(index);
                                  }}
                                />
                              }
                              label={reportType.value}
                            />
                          </FormGroup>
                        </div>
                      );
                    })}
                  </Box>
                </InputFormMolecule>
                <InputFormMolecule inputLabel="鳴門パースペクティブ">
                  <FormControl
                    sx={{ minWidth: 120, width: "80%" }}
                    size="small"
                  >
                    <InputLabel
                      style={{ zIndex: 1, backgroundColor: "white" }}
                      id="standard_tags"
                    >
                      観点を複数選択できます
                    </InputLabel>
                    <Select
                      style={{ zIndex: 0 }}
                      labelId="standard_tags"
                      multiple
                      id="standard_tags"
                      value={selectedStandardTagsArray}
                      renderValue={(selected) => {
                        return selected
                          .map((id: any) => {
                            return standardTags.content.filter(
                              (obj: any) => obj.id === id
                            )[0].tag_name;
                          })
                          .join(", ");
                      }}
                      label="standard_tags"
                      onChange={(event: SelectChangeEvent<any>) => {
                        const {
                          target: { value },
                        } = event;
                        setSelectedStandardTagsArray(
                          // On autofill we get a stringified value.
                          typeof value === "string" ? value.split(",") : value
                        );
                      }}
                    >
                      {standardTags.content.map((standardTag, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={standardTag.id}
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                          >
                            <Checkbox
                              checked={
                                selectedStandardTagsArray.filter((id: any) => {
                                  return id === standardTag.id;
                                }).length > 0
                              }
                            />
                            <ListItemText primary={standardTag.tag_name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </InputFormMolecule>
                <InputFormMolecule inputLabel="タグ">
                  <TextField
                    label="空白区切りで複数指定できます"
                    value={userTags ?? ""}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleClickSearchButton();
                      }
                    }}
                    onChange={(e) => handleSetUserTags(e)}
                    style={{ width: "80%", zIndex: 0 }}
                  />
                </InputFormMolecule>

                {state.userType === 0 && (
                  <>
                    <InputFormMolecule inputLabel="学籍番号">
                      <TextField
                        label="空白区切りで複数指定できます"
                        value={studentNumbersState ?? ""}
                        onChange={(e) => handleSetStudentNumbersState(e)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleClickSearchButton();
                          }
                        }}
                        style={{ width: "80%", zIndex: 0 }}
                      />
                    </InputFormMolecule>
                  </>
                )}
              </Box>
              <Box>
                <div className="flex items-top h-fit">
                  <Button
                    style={{
                      width: "100px",
                      zIndex: 0,
                      fontSize: "16px",
                    }}
                    variant="contained"
                    onClick={() => handleClickSearchButton()}
                  >
                    検索
                  </Button>
                  <Button
                    style={{
                      width: "100px",
                      zIndex: 0,
                      fontSize: "16px",
                      marginLeft: "20px",
                      backgroundColor: "gray",
                      transitionProperty: "opacity",
                      transitionDuration: "300ms",
                    }}
                    className="hover:opacity-100 opacity-70"
                    variant="contained"
                    onClick={() => handleClickClearButton()}
                  >
                    クリア
                  </Button>
                </div>
                <PaddingAtom></PaddingAtom>
                <div className="flex items-top h-fit">
                  <Button
                    style={{
                      width: "100px",
                      zIndex: 0,
                      fontSize: "16px",
                    }}
                    variant="contained"
                    onClick={() => handleClickOutputCSVButton()}
                  >
                    CSV出力
                  </Button>
                </div>
              </Box>
            </Box>
            <div className="w-fit">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isHighlight}
                      onClick={() => setIsHighlight(!isHighlight)}
                    />
                  }
                  label="代表文をハイライト表示"
                />
              </FormGroup>
            </div>
            <Paper style={{ width: "100%", padding: "10px" }}>
              <TableContainer>
                <Table aria-label="custom pagination table" stickyHeader>
                  <TableBody>
                    {reportsData.content.map(
                      (value: reportResponseType, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Box sx={{ flexDirection: "column" }}>
                              <div className="flex items-center">
                                <Link
                                  className="underline"
                                  to={`/reportDetail`}
                                  state={{
                                    userId: value.user_id,
                                    reportId: value.id,
                                  }}
                                >
                                  <BasicTextAtom>
                                    {value.report_date}
                                  </BasicTextAtom>
                                </Link>
                                <div className="ml-4">
                                  {
                                    reportTypesArray.filter((reportType) => {
                                      return (
                                        reportType.id === value.report_type
                                      );
                                    })[0].value
                                  }
                                </div>
                              </div>
                              <div className="flex items-center flex-wrap	">
                                {value.standard_tags.map((tag, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <BasicTextAtom>{tag}</BasicTextAtom>
                                      {index ===
                                        value.standard_tags.length - 1 || (
                                          <div className="px-2">/</div>
                                        )}
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                              <div className="flex items-center">
                                {value.user_tags.map((tag, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <BasicTextAtom>
                                        <span className="pr-2">#{tag}</span>
                                      </BasicTextAtom>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                              <BasicTextAtom>
                                <span className="whitespace-pre-wrap">
                                  {value.main_texts1.map((value, index) => {
                                    return value.type === 1 ? (
                                      isHighlight ? (
                                        <React.Fragment key={index}>
                                          <HighlightTextAtom>
                                            {value.text}
                                          </HighlightTextAtom>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment key={index}>
                                          {value.text}
                                        </React.Fragment>
                                      )
                                    ) : (
                                      <React.Fragment key={index}>
                                        {value.text}
                                      </React.Fragment>
                                    );
                                  })}
                                </span>
                              </BasicTextAtom>
                              <BasicTextAtom>
                                <span className="whitespace-pre-wrap">
                                  {value.main_texts2.map((value, index) => {
                                    return value.type === 1 ? (
                                      isHighlight ? (
                                        <HighlightTextAtom>
                                          {value.text}
                                        </HighlightTextAtom>
                                      ) : (
                                        value.text
                                      )
                                    ) : (
                                      value.text
                                    );
                                  })}
                                </span>
                              </BasicTextAtom>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Table>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={reportsData.total_count}
                      rowsPerPage={countPerPage}
                      labelRowsPerPage={"ページ毎の件数"}
                      rowsPerPageOptions={DefaultCountPerPages}
                      page={pageIndex}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          </>
        )
        }
      </Container >
    </>
  );
};
